import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Content, { HTMLContent, ContentType } from '../components/Content';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Img from 'gatsby-image';
import { ImageType } from '../components/Content';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

export const AboutPageTemplate = ({ title, image, content, contentComponent }: AboutPageTemplateType) => {
  const PageContent = contentComponent || Content;

  return (
    <Container>
      <h1>{title}</h1>
      <Row>
        <Col sm>
          <PreviewCompatibleImage
            imageInfo={{
              image: image,
              alt: 'Sabrina Hettich',
              className: 'mb-3'
            }}
          />
        </Col>
        <Col sm>
          <PageContent content={content} />
        </Col>
      </Row>
    </Container>
  );
};

type AboutPageTemplateType = {
  title: string,
  image: any | string,
  content?: string,
  contentComponent?: { (data: ContentType): any },
};

const AboutPage = ({ data }: AboutPageType) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        contentComponent={HTMLContent}
        content={post.html}
      />
    </Layout>
  );
};

type AboutPageType = {
  data: {
    markdownRemark: {
      html: string,
      frontmatter: {
        title: string,
        image: ImageType,
      },
    },
  },
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
